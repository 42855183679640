<template>
  <div class="inputAddDouble">
    <div class="inputAddDouble__setValues">
      <input class="inputAddDouble__input" type="text" ref="input_first" v-model="value" />
      <input class="inputAddDouble__input" type="text" ref="input_second" v-model="valueOther" />
      <button class="inputAddDouble__btnPlus" @click.prevent="onClickAdd" ref="btnadd_double" disabled><i class="fa fa-plus"></i></button>
    </div>
    <div class="inputAddDouble__showValues">
      <ol class="inputAddDouble__listContainer">
        <li class="inputAddDouble__listItem" v-for="(element, key) in elementsLocal" :key="'value' + key">
          <span class="inputAddDouble__item">{{ key + 1 }}.</span>
          <span class="inputAddDouble__item">{{ element }}</span>
          <span class="inputAddDouble__item">{{ elementsLocalOther[key] }}</span>
          <button class="inputAddDouble__btnDelete" @click.prevent="onDeleteElement(key)"><i class="fa fa-trash"></i></button>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elementsOne: { type: Array, default: () => [] },
    elementsTwo: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      elementsLocal: this.elementsOne,
      elementsLocalOther: this.elementsTwo,
      value: "",
      valueOther: "",
    };
  },
  watch: {
    value() {
      this._existData();
    },
    valueOther() {
      this._existData();
    },
  },
  methods: {
    onClickAdd() {
      this.elementsLocal.push(this.value);
      this.elementsLocalOther.push(this.valueOther);
      this.$emit("addValue", { elementsOne: this.elementsLocal, elementsTwo: this.elementsLocalOther });
      this.value = "";
      this.valueOther = "";
    },
    onDeleteElement(id) {
      this.elementsLocal.splice(id, 1);
      this.elementsLocalOther.splice(id, 1);
      this.$emit("addValue", { elementsOne: this.elementsLocal, elementsTwo: this.elementsLocalOther });
    },
    _existData() {
      if (this._someEmpty()) {
        this.$refs.btnadd_double.setAttribute("disabled", "true");
      } else {
        this.$refs.btnadd_double.removeAttribute("disabled");
      }
      this.verifyDataError();
    },
    verifyDataError() {
      this.$refs.input_first.classList.remove("error");
      this.$refs.input_second.classList.remove("error");
      if (this.value && !this.valueOther) {
        this.$refs.input_first.classList.remove("error");
        this.$refs.input_second.classList.add("error");
        return;
      }
      if (!this.value && this.valueOther) {
        this.$refs.input_first.classList.add("error");
        this.$refs.input_second.classList.remove("error");
        return;
      }
    },
    _someEmpty() {
      return this.value === "" || this.valueOther === "" ? true : false;
    },
  },
};
</script>
<style lang="scss">
.inputAddDouble {
  &__setValues,
  &__showValues {
    width: 100%;
    @include Flex(row, flex-start);
  }
  &__input {
    width: 100%;
    padding: 4px;
    margin: 0 2px;
    border-radius: 6px;
    border: 0.5px solid $silver;
    &.error {
      border-color: $primary_color;
    }
  }
  &__btnPlus {
    width: 29px;
    height: 29px;
    padding: 4px;
    align-self: flex-start;
    border-radius: 6px;
    background-color: $primary_color;
    border: none;
    cursor: pointer;
    color: $white;
    &[disabled] {
      background-color: $chicago;
    }
  }
  &__btnDelete {
    @include Flex(row);
    width: 20px;
    height: 20px;
    padding: 4px;
    border-radius: 6px;
    background-color: $primary_color;
    border: none;
    cursor: pointer;
    color: $white;
    justify-self: flex-end;
  }
  &__listContainer {
    width: 100%;
    @include Flex(column, flex-start, flex-start);
  }
  &__listItem {
    width: 100%;
    padding: 4px;
    display: grid;
    grid-template-columns: 6% 42% 42% 11%;
    align-items: flex-start;
    font-size: 90%;
    &[last] {
      justify-content: space-between;
    }
  }
  &__item {
    margin: 0 2px;
    word-break: break-word;
  }
  @media screen and(min-width:$tablet_width) {
    &__showValues {
      margin-top: 8px;
    }
    &__setValues {
      justify-content: space-between;
    }
    &__listItem {
      grid-template-columns: 3% 45% 42% 10%;
    }
    &__input {
      width: 45%;
    }
  }
}
</style>
