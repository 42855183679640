<template>
  <section class="interviewPrint" id="interviewPrint" docprint v-bind="$route.params.photos">
    <div class="interviewPrint__container">
      <h2 class="interviewPrint__title">Formulario de entrevista</h2>
      <form class="interviewPrint__Form">
        <section class="interviewPrint__card" pageBreak>
          <h4 class="interviewPrint__subtitle interviewPrint__subtitle--admin" colorTitle>Sección de coordinador</h4>
          <div class="interviewPrint__subContainerGroup interviewPrint__subContainerGroup--row">
            <label class="interviewPrint__labelTitle">Fecha de la entrevista:</label>
          </div>
          <h4 class="interviewPrint__subtitle interviewPrint__subtitle--admin">Información bancaria</h4>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Posee cuenta en Bancolombia?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasAccountBank" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasAccountBank" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerInput" margin="bottom">
            <label class="interviewPrint__labelTitle">Número de cuenta:</label>
            <p class="interviewPrint__inputWrite">_________________________________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Ha declarado renta?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasDeclarationRent" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasDeclarationRent" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle" margin="top">Observaciones:</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
          </div>
        </section>
        <section class="interviewPrint__card">
          <h4 class="interviewPrint__subtitle interviewPrint__subtitle--admin" colorTitle>Sección de modelo</h4>
          <div class="interviewPrint__subContainerGroup interviewPrint__subContainerGroup--row">
            <label class="interviewPrint__labelTitle">Fecha de la entrevista:</label>
          </div>
          <h4 :class="['interviewPrint__subtitle']">Información personal</h4>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Nombre completo:</label>
            <p class="interviewPrint__inputWrite">__________________________________________________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Tipo de documento:</label>
            <p class="interviewPrint__inputWrite">__________________</p>
            <label class="interviewPrint__labelTitle">Número de documento:</label>
            <p class="interviewPrint__inputWrite">____________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Edad:</label>
            <p class="interviewPrint__inputWrite">___________________________________</p>
            <label class="interviewPrint__labelTitle">Celular:</label>
            <p class="interviewPrint__inputWrite">____________________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Departamento:</label>
            <p class="interviewPrint__inputWrite">_______________________________</p>
            <label class="interviewPrint__labelTitle">Ciudad:</label>
            <p class="interviewPrint__inputWrite">______________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Dirección de residencia:</label>
            <p class="interviewPrint__inputWrite">____________________________________________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">¿Con quién vives?</label>
            <p class="interviewPrint__inputWrite">___________________________________________________________________</p>
          </div>
          <div class="interviewPrint__fieldSet" tittle="Hijos" :show="'false'">
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">¿Tienes hijos?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="hasSons" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="hasSons" />
                <label>No</label>
              </div>
            </div>
            <div class="interviewPrint__subContainerGroup">
              <label class="interviewPrint__labelTitle">Número de hijos:</label>
              <p class="interviewPrint__inputWrite">___________________________________________________________________</p>
            </div>
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">Rango de edad(puede seleccionar uno o varios)</label>
              <div class="container">
                <input class="mycheck squirtle mycheck--checkbox" type="checkbox" name="rangeYearsOldLessTwelve" />
                <label>0 años a 12 años</label>
              </div>
              <div class="container">
                <input class="mycheck squirtle mycheck--checkbox" type="checkbox" name="rangeYearsOldLessEighteen" />
                <label>12 años a 18 años</label>
              </div>
              <div class="container">
                <input class="mycheck squirtle mycheck--checkbox" type="checkbox" name="rangeYearsOldMostEighteen" />
                <label>Mayor de 18 años</label>
              </div>
            </div>
          </div>

          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">Facebook:</label>
            <p class="interviewPrint__inputWrite">________________________________</p>
            <label class="interviewPrint__labelTitle">Instagram:</label>
            <p class="interviewPrint__inputWrite">________________________________</p>
          </div>
        </section>
        <section class="interviewPrint__card">
          <h4 class="interviewPrint__subtitle">Educación</h4>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Nivel académico</label>
            <div class="container">
              <input class="mycheck circle" type="radio" />
              <label>Primaria</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" />
              <label>Secundaria</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" />
              <label>Técnico/Tecnólogo</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" />
              <label>Universitario</label>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Estudias actualmente?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasStudy" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasStudy" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerInput">
            <label class="interviewPrint__labelTitle">Horario:</label>
            <p class="interviewPrint__inputWrite">______________________________________________________________________________</p>
          </div>
        </section>
        <section class="interviewPrint__card">
          <h4 class="interviewPrint__subtitle">Información laboral</h4>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Trabajas Actualmente</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasWork" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasWork" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerInput" margin="bottom">
            <label class="interviewPrint__labelTitle">Ocupación:</label>
            <p class="interviewPrint__inputWrite">__________________________________________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Tienes disponibilidad de 8 horas diarias de Lunes a Sábado?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasDisponiblity" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasDisponiblity" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">
              Selecciona el horario que más te favorezca en caso de marcar todas, ingresa en el capo un numero del 1 al 3, siendo 1 tu primera opción
              y 3 tu última opción.
            </label>
            <div class="container">
              <input class="mycheck squirtle mycheck--checkbox" name="timeAvailabilityMorning" type="checkbox" />
              <label class="interviewPrint__labelCheck">06:00am a 02:00pm</label>
              <p class="interviewPrint__inputWrite">______</p>
            </div>
            <div class="container">
              <input class="mycheck squirtle mycheck--checkbox" name="timeAvailabilityLate" type="checkbox" />
              <label class="interviewPrint__labelCheck">02:00pm a 10:00pm</label>
              <p class="interviewPrint__inputWrite">______</p>
            </div>
            <div class="container">
              <input class="mycheck squirtle mycheck--checkbox" name="timeAvailabilityNight" type="checkbox" />
              <label class="interviewPrint__labelCheck">10:00pm a 06:00am</label>
              <p class="interviewPrint__inputWrite">______</p>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Cuál sería tu salario mensual ideal al cabo de:</label>
            <div class="container">
              <label class="interviewPrint__labelTitle">2 meses:</label>
              <p class="interviewPrint__inputWrite">___________________</p>
              <label class="interviewPrint__labelTitle">6 meses:</label>
              <p class="interviewPrint__inputWrite">___________________</p>
              <label class="interviewPrint__labelTitle">1 año:</label>
              <p class="interviewPrint__inputWrite">___________________</p>
            </div>
          </div>
          <div class="interviewPrint__fieldSet" tittle="Experiencia" :show="'false'">
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">¿Has trabajado como modelo webcam?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="hasExperienceWebCam" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="hasExperienceWebCam" />
                <label>No</label>
              </div>
            </div>
            <div class="interviewPrint__containerSectionsColumn">
              <div class="container">
                <label class="interviewPrint__labelTitle">Nombre del estudio:</label>
                <p class="interviewPrint__inputWrite">________________________________________________________________</p>
              </div>
              <div class="container">
                <label class="interviewPrint__labelTitle">¿Motivo por el cual decides retirarte?</label>
                <p class="interviewPrint__inputWrite">_______________________________________________</p>
              </div>
            </div>
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">Páginas en las que has trabajado</label>
              <ul class="interviewPrint__containerSectionsColumn">
                <li>______________________________________________________________________________________</li>
                <li>______________________________________________________________________________________</li>
                <li>______________________________________________________________________________________</li>
              </ul>
            </div>
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">Nombre de modelo en cada una</label>
              <ul class="interviewPrint__containerSectionsColumn">
                <li>______________________________________________________________________________________</li>
                <li>______________________________________________________________________________________</li>
                <li>______________________________________________________________________________________</li>
              </ul>
            </div>
            <div class="interviewPrint__containerSectionsColumn">
              <label class="interviewPrint__labelTitle">¿Cuánto tiempo de experiencia tienes como modelo webcam?</label>
              <div class="interviewPrint__containerInput">
                <p class="interviewPrint__inputWrite">__________</p>
                <label>Año(s)</label>
              </div>
              <label class="interviewPrint__labelTitle">Cuál ha sido tu mejor:</label>
              <div class="interviewPrint__containerInput">
                <label class="interviewPrint__labelTitle">Semana:</label>
                <p class="interviewPrint__inputWrite">_______________________</p>
              </div>
              <div class="interviewPrint__containerInput">
                <label class="interviewPrint__labelTitle">Quincena:</label>
                <p class="interviewPrint__inputWrite">_____________________</p>
              </div>
              <div class="interviewPrint__containerInput">
                <label class="interviewPrint__labelTitle">Mes:</label>
                <p class="interviewPrint__inputWrite">___________________________</p>
              </div>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Por cuánto tiempo te ves haciendo modelaje webcam?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="expectModelWebCam" />
              <label>Entre 1 a 3 años</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="expectModelWebCam" />
              <label>Entre 3 a 5 años</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="expectModelWebCam" />
              <label>Más de 5 años</label>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">
              De ser seleccionada, desearías bloquear Colombia u otro país o no tienes inconveniente con la privacidad
            </label>

            <div class="container">
              <input class="mycheck circle" type="radio" name="hasPrivacity" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasPrivacity" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Si deseas bloquear otro país, por favor ingrésalo</label>
            <p class="interviewPrint__inputWrite">______________________________________________________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">
              Tu familia, pareja sentimental o personas cercanas a tu entorno tiene algún inconveniente con que te desempeñes como modelo webcam:
            </label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="familyPermition" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="familyPermition" />
              <label>No</label>
            </div>
          </div>
        </section>
        <section class="interviewPrint__card aditional">
          <h4 class="interviewPrint__subtitle">Información adicional</h4>
          <div class="interviewPrint__containerSectionsColumnTable">
            <label class="interviewPrint__labelTitle">Del 1 - 10 ¿como te calificas? 1 es el puntaje más bajo y 10 el más alto</label>
            <div class="interviewPrint__radioSectionRow interviewPrint__radioSectionRow--head">
              <span class="firstColumn firstColumn--head"></span>
              <div class="interviewPrint__radioSectionRowCheck">
                <span class="head" v-for="id in 10" :key="id">{{ id }}</span>
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Actitud</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'attitud-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Paciencia</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'patience-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Disciplina</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'discipline-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Honestidad</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'honesty-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Puntualidad</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'puntuality-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Compromiso</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'commitment-' + id" type="radio" />
              </div>
            </div>
            <div class="interviewPrint__radioSectionRow">
              <span class="firstColumn">Apariencia física</span>
              <div class="interviewPrint__radioSectionRowCheck">
                <input class="mycheck circle" v-for="id in 10" :key="'appear-' + id" type="radio" />
              </div>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Has pensado en hacerte cirugía estética?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasThinkSurgery" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="hasThinkSurgery" />
              <label>No</label>
            </div>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">¿Qué te realizarías?</label>
            <p class="interviewPrint__inputWrite">_________________________________________________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">¿Qué es lo que más te gusta de tu cuerpo?</label>
            <p class="interviewPrint__inputWrite">_________________________________________</p>
          </div>
          <div class="interviewPrint__subContainerGroup">
            <label class="interviewPrint__labelTitle">¿Qué es lo que no te gusta de tu cuerpo?</label>
            <p class="interviewPrint__inputWrite">__________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Qué te motiva a buscar sobre la industria del modelaje webcam?</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Cuáles son sus aspiraciones o metas en:</label>
            <div class="interviewPrint__containerInput">
              <label class="interviewPrint__labelTitle">1 año:</label>
              <p class="interviewPrint__inputWrite">____________________________________________________________________________</p>
            </div>
            <div class="interviewPrint__containerInput">
              <label class="interviewPrint__labelTitle">3 años:</label>
              <p class="interviewPrint__inputWrite">____________________________________________________________________________</p>
            </div>
            <div class="interviewPrint__containerInput">
              <label class="interviewPrint__labelTitle">5 años:</label>
              <p class="interviewPrint__inputWrite">____________________________________________________________________________</p>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Por qué te gustaría ser parte de Models1A?</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Cómo te enteraste de la agencia?</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <div class="interviewPrint__containerInput special" margin="top">
              <div class="interviewPrint__containerInput" margin="none">
                <input class="mycheck squirtle mycheck--checkbox" type="checkbox" />
                <label class="interviewPrint__checkInput interviewPrint__labelTitle">Recomendada por:</label>
              </div>
              <p class="interviewPrint__inputWrite">_____________________________________________________________</p>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">¿Qué modelos conoces de Models 1A?</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <div class="interviewPrint__containerInput special" margin="top">
              <div class="interviewPrint__containerInput" margin="none">
                <input class="mycheck squirtle mycheck--checkbox" type="checkbox" />
                <label class="interviewPrint__checkInput interviewPrint__labelTitle">¿Con qué modelo te identificas?</label>
              </div>
              <p class="interviewPrint__inputWrite">_______________________________________________</p>
            </div>
          </div>
          <div class="interviewPrint__containerSectionsColumn">
            <label class="interviewPrint__labelTitle">Comentarios</label>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
            <p class="interviewPrint__inputWrite">_____________________________________________________________________________________</p>
          </div>
        </section>
        <section class="interviewPrint__card photos">
          <h4 class="interviewPrint__subtitle">Fotos</h4>
          <div class="interviewPrint__photosContainer" v-for="(photo, id) in $route.params.photos" :key="id">
            <img class="interviewPrint__photos" :src="photo.link" :alt="photo.name" />
          </div>
        </section>
      </form>
    </div>
  </section>
</template>
<script>
import inputAdd from "@/components/inputs/InputAdd.vue";
import InputAddDouble from "@/components/inputs/InputAddDouble.vue";

export default {
  components: { inputAdd, InputAddDouble },
  data: function () {
    return {
      photos: [],
    };
  },
  mounted() {
    setTimeout(() => {
      window.print();
      this.$router.go(-1);
    }, 100);
  },
};
</script>

<style lang="scss">
.interviewView {
  background-color: white;
}
.interviewPrint {
  display: none;
  @media print {
    *[margin="bottom"] {
      margin-bottom: 33px;
    }
    *[margin="top"] {
      margin-top: 16px;
    }
    *[margin="none"] {
      margin: 0;
    }
    *[pageBreak] {
      page-break-after: always;
    }
    display: block;
    width: 100%;
    &__Form {
      width: 690px;
    }
    &__container {
      margin: 0;
    }
    &__containerSelects,
    &__containerInput,
    &__subContainerGroup,
    &__containerSectionsColumn {
      @include Flex(row, flex-start);
      gap: 5px;
      margin: 15px 0;
    }
    &__card {
      display: block;
      width: auto;
      max-width: $tablet_width;
      margin: 20px 0;
      &.aditional {
        page-break-before: always;
      }
      &.photos {
        page-break-before: always;
        display: block;
      }
    }
    &__labelCheck {
      width: 165px;
    }
    &__title {
      margin: 0;
    }
    &__subtitle {
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
    }
    &__labelTitle {
      font-weight: bold;
    }
    &__inputYear {
      padding: 8px;
      width: 142px;
      margin: 4px 8px 0 0;
    }
    &__containerSectionsColumnTable {
      margin: 15px 0;
    }
    &__containerSectionsColumn {
      @include Flex(column, flex-start, flex-start);
      gap: 10px;
      .container {
        @include Flex(row, flex-start);
        gap: 5px;
      }
    }
    &__radioSectionRowCheck {
      display: grid;
      grid-template-columns: repeat(10, 20px);
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      width: 80%;
    }
    &__radioSectionRow {
      @include Flex(row, flex-start);
      margin: 8px 0;
      &--head {
        margin-top: 8px;
      }
      .head {
        text-align: center;
        padding: 0 5px;
      }
      .firstColumn {
        width: 20%;
      }
    }
    &__photosContainer {
      margin: 50px 0;
    }
    &__photos {
      display: block;
      width: 400px;
      max-height: 350px;
      object-fit: cover;
    }
  }
}
</style>
