<template>
  <div class="inputAdd">
    <div class="inputAdd__setValue">
      <input class="inputAdd__input" type="text" v-model="value" />
      <button class="inputAdd__btnPlus" @click.prevent="onClickAdd" ref="btnadd" disabled><i class="fa fa-plus"></i></button>
    </div>
    <div class="inputAdd__showValues">
      <ol class="inputAdd__listContainer">
        <li class="inputAdd__listItem" v-for="(element, key) in elementsLocal" :key="key">
          <span>{{ key + 1 }}.</span>
          <span overflowText>{{ element }}</span>
          <button class="inputAddDouble__btnDelete" @click.prevent="onDeleteElement(key)"><i class="fa fa-trash"></i></button>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: { elements: { type: Array, default: () => [] } },
  data: function () {
    return {
      elementsLocal: this.elements,
      value: "",
    };
  },
  watch: {
    value(newValue) {
      if (newValue === "") {
        this.$refs.btnadd.setAttribute("disabled", "true");
      } else {
        this.$refs.btnadd.removeAttribute("disabled");
      }
    },
  },

  methods: {
    onClickAdd() {
      this.elementsLocal.push(this.value);
      this.$emit("addValue", this.elementsLocal);
      this.value = "";
    },
    onDeleteElement(id) {
      this.elementsLocal.splice(id, 1);
      this.$emit("addValue", this.elementsLocal);
    },
  },
};
</script>
<style lang="scss">
.inputAdd {
  &__setValue,
  &__showValues {
    width: 100%;
    @include Flex(row, flex-start);
  }
  &__input {
    width: 100%;
    padding: 4px;
    margin: 0 2px;
    border-radius: 6px;
    border: 0.5px solid $silver;
  }
  &__btnPlus {
    width: 29px;
    height: 29px;
    padding: 4px;
    align-self: flex-start;
    border-radius: 6px;
    background-color: $primary_color;
    border: none;
    cursor: pointer;
    color: $white;
    &[disabled] {
      background-color: $chicago;
    }
  }
  &__btnDelete {
    @include Flex(row);
    width: 20px;
    height: 20px;
    padding: 4px;
    border-radius: 6px;
    background-color: $primary_color;
    border: none;
    cursor: pointer;
    color: $white;
    justify-self: flex-end;
  }
  &__listContainer {
    width: 100%;
    @include Flex(column, flex-start, flex-start);
    margin-top: 4px;
  }
  &__listItem {
    width: 100%;
    padding: 4px;
    font-size: 90%;
    display: grid;
    grid-template-columns: 15px auto 20px;
    // grid-template-columns: 6% 83% 11%;
    align-items: flex-start;
    &[last] {
      justify-content: space-between;
    }
  }
  &__item {
    margin: 0 2px;
    word-break: break-word;
  }
  @media screen and(min-width:$tablet_width) {
    &__showValues {
      margin-top: 8px;
    }
    &__setValues {
      justify-content: space-between;
    }
    &__input {
      width: 100%;
    }
  }
}
</style>
